import { N20MainMenu, N50Breadcrumb, N60Footer } from '~/features/Navigation';
import { MetaFields } from '../../pages/shared/MetaFields';
import { StyledMain } from '../styled';
import DynamicScripts from '../utils/DynamicScripts';
import useDynamicHooks from '../utils/useDynamicHooks';

export default function Standard({ children }: { children?: React.ReactNode }) {
    useDynamicHooks();

    return (
        <>
            <MetaFields />
            <DynamicScripts />
            <N20MainMenu />
            <StyledMain>
                <N50Breadcrumb />
                {children}
            </StyledMain>
            <N60Footer />
        </>
    );
}
